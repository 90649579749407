/* label{
    color: red;
}
label:focus-within p{
	color: #1890ff;
} */

.AddAlertForm .ant-form-item-label > label {
	color: #b0b4c0 !important;
}
.AddAlertForm .ant-select-selector {
	padding: 0 !important;
	padding-left: 10px !important;
}
.AddAlertForm .ant-select-selector {
	border: 1px solid #e6e7e9 !important;
}

.hideformItem {
	min-height: 0%;
	margin-bottom: 0px;
}
.hello1 {
	min-height: 0%;
	margin-bottom: 24px;
}
