.cardTable-header .ant-card-head-title {
	font-size: 22px;
	font-weight: 500;
}
.charttable-card-head .ant-card-head {
	background: #f6f7f9 !important;
	border: 1px solid #caced4 !important;
}
.cardTable-header {
	position: relative;
}
.cardTable-header-button {
	position: absolute;
	top: 21px;
	right: 10px;
}
.charttable-card-head {
	width: 100%;
}
