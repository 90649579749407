.login .ant-carousel {
  width: 100%;
}
.login-left {
  background-image: url("../assets/images/bgImage.png");
  width: 100%;
  position: relative;
  height: 100vh;
}
.slick-track {
  background-color: none;
}
.remember-me .ant-form-item .ant-form-item-control {
  margin-left: 0px !important;
}
.bcolor {
  color: #1e6efe;
}
.bluecolor {
  color: #1e6efe;
}
label:focus-within p {
  color: #1890ff;
}
.loginPage .ant-card-bordered {
  border: none;
}
.position-absolute {
  position: absolute;
  bottom: 20vh;
}
.font-24 {
  font-size: 24px;
  font-weight: lighter;
}
.login-page-margin-0 .ant-row {
  margin: 0px;
}
