.dropdown-machine {
	position: relative;
	background: red;
	width: 238px;
	border: 1px solid #dadee4;
	background: #ffffff;
	padding: 5px 10px;
	border-radius: 5px;
	color: #a3a9b4;
}
.dropdown-machine span {
	position: absolute;
	right: 10px;
	top: 10px;
}
.graybackground section {
	background: none;
}
.graybackground {
	background: #f6f7f9;
}
.dropdown-machine {
	width: 270px;
}
@media only screen and (max-width: 600px) {
	.dropdown-machine {
		width: 170px;
	}
}
.inline-div {
	width: 100%;
	display: inline;
}
.width-according-to-sidebar-70 {
	padding: 20px;
	width: 70%;
	float: left;
}

@media only screen and (max-width: 600px) {
	.width-according-to-sidebar-70 {
		padding: 20px;
		width: 100%;
		float: left;
	}
}
.width-according-to-sidebar-30 {
	width: 30%;
	float: right;
}
@media only screen and (max-width: 600px) {
	.width-according-to-sidebar-30 {
		width: 100%;
		float: right;
	}
}

.mobile-margin-top .ant-card-body {
	padding: 10px;
}
.critical-alerts {
	margin-left: 40px;
	margin-top: 5px;
}
.critical-alerts:before {
	content: "";
	position: absolute;
	top: 0 !important;
	left: 0 !important;
	width: 33px;
	height: 34px;
	/* background-image: url("../../assets/images/alert.png"); */
	/* opacity: .7; */
}
.faulty-alerts {
	margin-left: 40px;
	margin-top: 28px;
}
.faulty-alerts:before {
	content: "";
	position: absolute;
	top: 45 !important;
	left: 0 !important;
	width: 33px;
	height: 34px;
	/* background-image: url("../../assets/images/warning.png"); */
}
.machine-icon {
	font-size: 20px;
	float: right;
	padding-right: 10px;
	color: #a3a9b4;
}
@media only screen and (max-width: 600px) {
	.machine-icon {
		float: left;
		margin-top: 15px;
	}
}
.mt-1 {
	margin-top: 0px;
}
@media only screen and (max-width: 600px) {
	.mt-1 {
		margin-top: 30px;
	}
}

.health-icon {
	margin-bottom: 0px;
}
.centre-align-icon {
	font-size: 25px;
	color: #404040;
	margin-top: 10px;
}
.health-counter {
	font-size: 24px;
	font-weight: bold;
	text-align: center;
	margin-bottom: 0px;
}
.health-status {
	color: #404040;
	text-align: center;
	margin-bottom: 0px;
}
.border-right {
	border-right: 1px solid #dadee4;
}

.running-icon {
	margin-bottom: 0px;
}
.centre-align-icon {
	font-size: 25px;
	color: #404040;
	margin-top: 10px;
}
.running-counter {
	font-size: 24px;
	font-weight: bold;
	text-align: center;
	margin-bottom: 0px;
}
.running-status {
	color: #404040;
	text-align: center;
	margin-bottom: 0px;
}
.border-right {
	border-right: 1px solid #dadee4;
}

.ant-card-body {
	padding: 0px;
}
.dropdown-formula-creation {
}
@media only screen and (max-width: 600px) {
  .responsive-margin-60 {
    margin-top: 60px !important;
  }
}
