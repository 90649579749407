.ant-card-head {
  font-size: 14px;
}
.onHover:hover{
  border: 1px solid #1890ff;
}
.machine-info-card .ant-card-bordered {
  border: 1px solid #caced4 ;
  border-radius: 10px;
}
.machine-info-card .ant-card-head {
  border-bottom: none;
}
.machine-info-card .ant-card-head-title {
  padding: 10px 0px;
}
.machine-info-card .ant-card-head {
  padding: 0px 10px;
}
.machine-info-card .ant-card-body {
  padding: 10px;
}
.machine-info-card-icon {
  font-size: 20px;
  width: 100%;
}
.machine-info-card-counter {
  font-weight: 500;
  font-size: 14px;
  margin-top: -4px;
  margin-bottom: 0px;
}
.machine-info-card-content {
  font-size: 11px;
  margin-top: 0px;
  margin-bottom: 0px;
}
.border-bottom {
  border-bottom: 1px solid #bbc1ce;
}
.machine-info-card-image {
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.padding-bottom {
  padding-bottom: 10px !important;
}
.exclaimationSign{
  position: absolute;
  right: 10px;
  top:-10px;
  font-size: 25px;
}
.onHover:hover{
  border: 1px solid #1e6efe !important;
}





