.ant-modal-header {
	border-bottom: 0px;
}

.ant-modal-content {
	border-radius: 10px;
	border: 2px solid white;	
}

.ant-form label :active,
Input :active {
	color: #1e6efe;
}
.modal-label{
	margin-bottom:5px;
}
.modal-custom input{
	margin-top:5px;
}

label:focus-within p{
	color: #1890ff;
}