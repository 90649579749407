.cardTable-header .ant-card-head-title {
	font-size: 22px;
	font-weight: 500;
}
.charttable-card-head .ant-card-head {
	background: #f6f7f9 !important;
	border: 1px solid #caced4 !important;
}
.cardTable-header {
	position: relative;
}
.cardTable-header-button {
	position: absolute;
	top: 21px;
	right: 10px;
}
.charttable-card-head {
	width: 100%;
}
.showHideButton Button{
	font-size: 18px;
}
.x-axis-color{
	background: #1909ad;
	height: 22px;
	width: 50px;
	margin-right:'10px';
	border-radius:'15%';
}
.alerts-color{
	background: #ff0000;
	height: 22px;
	width: 50px;
	margin-right:'10px';
	border-radius:'15%';
}

.y-axis-color{
	background: #09AD19;
	height: 22px;
	width: 50px;
	margin-right:'10px';
	border-radius:'15%';
}

.z-axis-color{
	background: #0681f3;
	height: 22px;
	width: 50px;
	margin-right:'10px';
	border-radius:'15%';
}






