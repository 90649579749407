.cardTable-header .ant-card-head-title {
  font-size: 18px !important;
  font-weight: 700 !important;
}
.charttable-card-head .ant-card-head {
  background: #f6f7f9 !important;
  border: 1px solid #caced4 !important;
}
.cardTable-header {
  position: relative;
}
.cardTable-header-button {
  position: absolute;
  top: 21px;
  right: 10px;
}
.charttable-card-head {
  width: 100%;
}
.radio-button-custom {
  padding: 0px 20px 0px 20px;
  background: white;
  color: #a3a9b4;
}
