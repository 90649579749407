.same-button-width {
	min-width: 160px;
	max-width: 160px;
}
.heading-small {
	font-size: 24px;
	font-weight: bold;
}
.searchBar-table .ant-input-group-addon .ant-btn {
	border-left: 0px;
	border-radius: 3px;
}
td,
th {
	font-weight: 500;
}
