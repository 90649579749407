.width-190 {
  min-width: 190px;
  max-width: 190px;
}
@media only screen and (max-width: 600px) {
  .responsive-margin-70 {
    margin-top: 70px !important;
  }
}
@media only screen and (max-width: 600px) {
  .responsive-button {
    margin-bottom: 10px;
  }
}
