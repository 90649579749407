@media only screen and (max-width: 600px) {
  .responsive-margin-60 {
    margin-top: 60px !important;
  }
}
.drag-and-drop-space {
  width: 350px !important;
}
@media only screen and (max-width: 600px) {
  .drag-and-drop-space {
    width: 300px !important;
  }
}
