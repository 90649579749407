.p-relative {
  position: relative;
}
.sidebar {
  min-height: 100vh;
  border-right: 1px solid #dadee4;
  /* width: 250px;
  min-width: 250px;
  max-width: 250px; */
}
@media only screen and (max-width: 600px) {
  .sidebar {
    position: fixed;
    z-index: 10000;
    width: auto !important;
    height: 100vh;
    margin-top: 60px;
  }
}
.ant-layout-sider {
  background: none;
}
.ant-layout-sider-children {
  background: white;
}
.bcolor {
  color: #14212b;
}
.ant-menu-item-group-title {
  display: none;
}
.ant-menu-sub.ant-menu-inline {
  background: white;
}
.ant-menu-item {
  padding-left: 30px !important;
}
