.card-para-heading {
  font-weight: bold;
  margin-top: 10px;
}
.counter {
  position: absolute;
  font-size: 72px;
  padding: 0;
  margin: 0;
  top: -20px;
  right: 10px;
  bottom: 20px;
}
@media only screen and (max-width: 1000px) {
  .counter {
    right: -10px;
  }
}

@media only screen and (max-width: 600px) {
  .responsive-margin-60 {
    margin-top: 60px;
  }
}
@media only screen and (max-width: 600px) {
  .counter {
    right: 0px;
  }
}
.blue {
  color: #1e6efe;
}
.red {
  color: #ca2b30;
}
.green {
  color: #09c38e;
}
.orange {
  color: #ffaa00;
}
@media only screen and (max-width: 600px) {
  .sidebar-move {
    position: absolute;
    display: inline;
    right: 10px;
    top: 17px;
  }
}
@media only screen and (max-width: 600px) {
  .mobile-margin-top {
    margin-top: 10px !important;
  }
}
.right-align-button {
  margin-right: 10px;
  margin-left: auto;
  float: right;
}
