/* label{
    color: red;
}
label:focus-within p{
	color: #1890ff;
} */

.AddAllertForm .ant-form-item-label > label {
    color: #B0B4C0 !important;
}
.AddAllertForm .ant-select-selector {
    padding: 0 !important;
    padding-left: 10px !important;
}
.AddAllertForm .ant-select-selector {
    border: 1px solid #E6E7E9!important;
}