.recharts-cartesian-grid-vertical line {
  display: none;
}
.recharts-line-dots circle {
  display: none;
}
.recharts-legend-wrapper {
  display: none;
}
.testtest {
  width: 900px;
}
