@font-face {
  font-family: font-regular;
  src: url("./assets/fonts/Axiforma-Regular.woff");
}
@font-face {
  font-family: font-medium;
  src: url("./assets/fonts/Axiforma-SemiBold.woff");
  font-weight: 600;
}
@font-face {
  font-family: font-bold;
  src: url("./assets/fonts/Axiforma-Bold.woff");
  font-weight: bold;
}
html,
body {
  font-family: font-regular !important;
}
.margin-0 {
  margin: 0;
}
.b-color {
  color: #1e6efe;
}
.bcolor {
  color: #1e6efe;
}
.gcolor {
  color: #788090;
}
.rcolor {
  color: #ca2b30;
}
.ycolor {
  color: #ffaa00;
}
.text-center {
  text-align: center;
}
.test {
  font-family: "Axiforma", "Regular";
}
.p-0 {
  padding: 0 !important;
}
.m-0 {
  margin: 0 !important;
}
.m-auto {
  margin: auto !important;
}
.ml-1 {
  margin-left: 10px;
}
.ml-2 {
  margin-left: 15px;
}
.ml-3 {
  margin-left: 20px;
}
.ml-4 {
  margin-left: 25px;
}
.ml-5 {
  margin-left: 30px;
}
.mt-1 {
  margin-top: 20px !important;
}

.font-weight-bold {
  font-weight: 600;
}
.font-20 {
  font-size: 20px;
}
.font-24 {
  font-size: 24px;
}
.w-100 {
  width: 100%;
}
.w-50 {
  width: 50%;
}
.test-width {
  width: 90%;
}
.border-right {
  border-right: 1px solid black;
}
.text-center {
  text-align: center;
}
.ant-switch-checked {
  background-color: #09c38e;
}
