@import "~antd/dist/antd.css";

.header {
  position: relative;
}
@media only screen and (max-width: 600px) {
  .header {
    position: fixed;
    z-index: 1000000;
    width: 100%;
    height: 60px;
  }
}
.ant-layout-header {
  background: white !important;
}
.user-image {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-right: 10px;
}
.header ul li {
  color: black !important;
  background: white;
}
.header ul {
  color: black !important;
  background: white;
}
.ant-menu-horizontal {
  border-bottom: none;
}
.ant-select-selector {
  border: none !important;
  background: none !important;
}
.notification {
  background-color: white;
  text-decoration: none;
  /* padding: 15px 26px; */
  position: relative;
  display: inline-block;
  border-radius: 2px;
}
/* Its need to be responsive */
.notification-width {
  width: 300px;
  height: 432px;
  overflow-y: scroll;
  border: 1px solid #caced4;
}
/* .testumair .ant-dropdown::after {
  content: "" !important;
  position: absolute !important;
  right: 30px !important;
  top: -15px !important;
  border-top: none !important;
  border-right: 15px solid transparent !important;
  border-left: 15px solid transparent !important;
  border-bottom: 15px solid black !important;
} */
.notification-width::-webkit-scrollbar {
  width: 3px;
}
.notification-width::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px white;
  border-radius: 10px;
}

.notification-width::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px #1e6efe;
}
.notification:hover {
  background: red;
}
.notification-heading {
  padding-left: 10px;
  margin: 0;
}
.border-bottom-notification {
  border-bottom: 1px solid #caced4;
}

.notification .badge {
  /* padding: 5px 10px; */
  border-radius: 50%;
  background: red;
  color: white;
}
@media only screen and (max-width: 600px) {
  .header ul {
    display: none;
  }
}
.header ul {
  background: #e8f0ff;
}
.logo-image {
  margin-left: 10px;
}
.sidebar-move {
  display: none;
}
@media only screen and (max-width: 600px) {
  .sidebar-move {
    position: absolute;
    display: inline;
    right: 10px;
    top: 17px;
  }
}
.bell-icon-header {
  /* position: absolute;
  right: 15%; */
  font-size: 18px;
}
.noborder-dropdown {
  margin: 10px 20px;
}
.noborder-dropdown .ant-btn {
  background: none;
  border: none;
}
.badge {
  margin-right: -90px;
}
@media only screen and (max-width: 600px) {
  .noborder-dropdown {
    margin-right: 55px;
  }
}
.bell-icon-header .header-notification-count {
  position: absolute;
  top: -10px;
  right: -10px;
  padding: 0px 0px;
  border-radius: 50%;
  background-color: red;
  color: white;
}
.noborder-dropdown .ant-dropdown-trigger {
  border: none;
  margin-top: 7px;
}
.badge-notifications {
  position: relative;
  background: #145252;
  border-radius: 50%;
  color: white;
  text-align: center;
  height: 30px;
  width: 30px;
}
.badge-notifications p {
  position: absolute;
  top: 35%;
  left: 60%;
  margin: -15% 0 0 -25%;
}
.date-notification {
  color: #caced4;
}
