.add-icon {
  font-weight: bold;
}
.right-sidebar1 .ant-card {
  border: 1px solid #caced4;
  width: 95%;
  margin: auto;
}
.right-sidebar1 .ant-card-body {
  padding: 10px;
}
.right-sidebar1 .ant-card-head-title {
  font-weight: bold;
  font-size: 20px;
}
.right-sidebar1 .ant-card-head {
  background: #f6f7f9;
}
.sidebar-searchbar {
  border-radius: 5px;
}
.sidebar-searchbar input {
  background: #f7f8fa;
}
.sidebar-searchbar button {
  background: #f7f8fa;
}
.sidebar-searchbar .ant-input-search-button {
  border-left: none;
}
.sidebar-heading {
  font-size: 18px;
}
.sidebar-heading-span {
  font-size: 14px;
  color: #14212b;
}
.date-status {
  color: #caced4;
  font-size: 16px;
}
.sidebar-collaps {
  margin-top: 20px;
}
.collaps-content {
  font-size: 14px;
  font-weight: 500;
}
.comment {
  background: #efefef;
  border-radius: 5px;
  height: 45px;
}
.comment input {
  background: #efefef;
}
.ant-collapse-content-box {
  border: 1px solid #788090;
}
.ant-collapse-header {
  border-top: 1px solid #788090;
  border-left: 1px solid #788090;
  border-right: 1px solid #788090;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  /* border-bottom-right-radius: 10px !important; */
  /* border-bottom-right-radius: 10px !important; */
}
.right-sidebar .ant-collapse {
  border: none;
}
.sidebar-collaps .ant-collapse-header {
}
